import React, { useState } from "react";

function Form({ onStart }) {
  const [formData, setFormData] = useState({
    email: "",
    school: "",
    city: "",
    certif: false,
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "email":
        if (!value) error = "L'email est requis";
        else if (!validateEmail(value)) error = "Veuillez entrer un email valide";
        break;
      case "school":
        if (!value) error = "L'établissement scolaire est requis";
        break;
      case "city":
        if (!value) error = "La ville est requise";
        break;
      // case "certif":
      //   if (!value) error = "Vous devez accepter de recevoir des mails";
      //   break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (errors[name]) {
      const newErrors = { ...errors };
      const errorMessage = validateField(name, newValue);
      if (!errorMessage) {
        delete newErrors[name];
      } else {
        newErrors[name] = errorMessage;
      }
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    Object.keys(formData).forEach((key) => {
      const errorMessage = validateField(key, formData[key]);
      if (errorMessage) {
        newErrors[key] = errorMessage;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      onStart();
    }
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === "checkbox" ? checked : value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let newErrors = {};

  //   if (!formData.email || !validateEmail(formData.email)) {
  //     newErrors.email = "Veuillez entrer un email valide";
  //   }
  //   if (!formData.school) {
  //     newErrors.school = "L'établissement scolaire est requis";
  //   }
  //   if (!formData.city) {
  //     newErrors.city = "La ville est requise";
  //   }
  //   // if (!formData.certif) {
  //   //   newErrors.certif = "Vous devez accepter de recevoir des mails";
  //   // }

  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //   } else {
  //     setErrors({});
  //     onStart();
  //   }
  // };

  return (
    <div className="row flex-grow-1">
      <div className="col-6 d-flex flex-column px-5 border-end">
        <div style={{ fontSize: "83px", fontFamily: "Caveat" }}>
          C’est parti !
        </div>
        <div className="fw-bold" style={{ fontSize: "19px" }}>
          TU ES MAINTENANT PRÊT(E)<br /> À COMMENCER TON QUIZZ
        </div>
        <div className="flex-grow-1"></div>
        <div className="text-center">
          <img src="/img/result.png" alt="question" className="img-fluid" />
        </div>
      </div>
      <div className="col-6 d-flex px-5">
        <form onSubmit={handleSubmit} noValidate>
          <div className="fw-bold">
            Remplis ces informations pour pouvoir débuter le quizz.
          </div>
          <div className="mt-5">
            <input type="text" name="email" className="form-control" placeholder="E-mail de l’un de mes parents" value={formData.email} onChange={handleChange} required />
            {errors.email && <div className="color or ms-3 mt-1">{errors.email}</div>}

            <input type="text" name="school" className="form-control mt-3" placeholder="Établissement scolaire" value={formData.school} onChange={handleChange} required />
            {errors.school && <div className="color or ms-3 mt-1">{errors.school}</div>}

            <input type="text" name="city" className="form-control mt-3" placeholder="Ville" value={formData.city} onChange={handleChange} required />
            {errors.city && <div className="color or ms-3 mt-1">{errors.city}</div>}

            <div className="form-check mt-4">
              <input name="certif" className="form-check-input" type="checkbox" id="flexCheckDefault" checked={formData.certif} onChange={handleChange} />
              <label className="form-check-label fw-bold" htmlFor="flexCheckDefault">
                J’accepte de recevoir des mails de RATPDEV
                {errors.certif && <div className="color or mt-1">{errors.certif}</div>}
              </label>
            </div>
            <div style={{ fontSize: "10px", fontStyle: "italic" }}>
              En renseignant votre adresse e-mail, vous acceptez de recevoir des communications de RATPDEV.
              Vous pouvez vous désinscrire à tout moment via le lien présent dans nos e-mails.
              Pour en savoir plus sur la gestion de vos données personnelles, consultez notre Politique de confidentialité.
            </div>
          </div>
          <button type="submit" className="mybutton sapin w-100 mt-5">
            Je participe au quizz
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
