import React, { useEffect, useState, useRef } from "react";
import InteractiveImage from "./InteractiveImage";
import Quiz from "./qcm/Quiz";
import { Howl } from "howler";

function Game({ isGameInteractive }) {
  const isDialogueOpenRef = useRef(false);
  const isDraggingRef = useRef(false);
  const [backgroundImage, setBackgroundImage] = useState("tableau1.png");
  const [isMuted, setIsMuted] = useState(false);
  const [isFormulaire, setIsFormulaire] = useState(false);
  const [sound, setSound] = useState(null);

  useEffect(() => {
    // Initialiser le son une seule fois
    const newSound = new Howl({
      src: ["audio/BruitDeFond.mp3"],
      volume: 0.3,
      loop: true,
      onplay: () => { console.log("Audio started"); },
      onend: () => { console.log("Audio ended"); },
    });

    setSound(newSound); // Met à jour l'état avec l'objet Howl
    newSound.play(); // Joue le son

    // Nettoyage : arrêter le son lorsque le composant est démonté
    return () => {
      newSound.stop();
    };
  }, []);

  const toggleSound = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (sound) {
      sound.mute(isMuted);
      console.log(isMuted ? "Sound muted" : "Sound activated");
    }
  }, [isMuted, sound]);

  const toggleFormulaire = () => {
    setIsFormulaire(!isFormulaire);
  };

  const disableZoom = false;

  useEffect(() => {
    const element = document.querySelector(".game-container");
    let isDown = false;
    let startX, startY, scrollLeft, scrollTop;

    function handleMouseDown(e) {
      isDown = true;
      startX = e.pageX - element.offsetLeft;
      startY = e.pageY - element.offsetTop;
      scrollLeft = element.scrollLeft;
      scrollTop = element.scrollTop;
    }

    function handleMouseMove(e) {
      if (!isDown) return;
      if (isDialogueOpenRef.current) return;
      e.preventDefault();
      isDraggingRef.current = true;
      const x = e.pageX - element.offsetLeft;
      const y = e.pageY - element.offsetTop;
      const walkX = x - startX;
      const walkY = y - startY;
      element.scrollLeft = scrollLeft - walkX;
      element.scrollTop = scrollTop - walkY;
    }

    function handleMouseUpOrLeave() {
      setTimeout(() => {
        isDraggingRef.current = false;
      }, 100);

      isDown = false;
    }

    // Ajouter des écouteurs d'événements pour mousedown, mouseup, mouseleave, mousemove
    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      element.addEventListener("mouseup", handleMouseUpOrLeave);
      element.addEventListener("mouseleave", handleMouseUpOrLeave);
      element.addEventListener("mousemove", handleMouseMove);
    }


    // Disable Zoom
    const handleWheel = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && (event.key === "+" || event.key === "-")) {
        event.preventDefault();
      }
    };

    if (disableZoom == true) {
      document.addEventListener("wheel", handleWheel, { passive: false });
      document.addEventListener("keydown", handleKeyDown);
    }
    // End Disable Zoom

    // Suppression des écouteurs d'événements lors du nettoyage
    return () => {
      if (element) {
        element.removeEventListener("mousedown", handleMouseDown);
        element.removeEventListener("mouseup", handleMouseUpOrLeave);
        element.removeEventListener("mouseleave", handleMouseUpOrLeave);
        element.removeEventListener("mousemove", handleMouseMove);

        if (disableZoom == true) {
          document.removeEventListener("wheel", handleWheel);
          document.removeEventListener("keydown", handleKeyDown);
        }
      }
    };
  }, []);

  // useEffect(() => {
  //   console.log("isDragging changed:", isDragging);
  // }, [isDragging]);

  return (
    <>
      {isGameInteractive && (
        <div className="action">
          <div className="info_sound">
            <img src="/img/info.png" alt="info" className="img-fluid" />
            <img src={isMuted ? "/img/sound-off.png" : "/img/sound.png"} alt="sound" className="img-fluid" onClick={toggleSound} />
            {/* <button onClick={toggleFormulaire}>
              Formulaire
            </button> */}
          </div>
        </div>
      )}
      {isFormulaire && (
        <Quiz />
      )}
      <div className="game-container">
        <div className="game-layer" style={{ backgroundImage: `url(../img/${backgroundImage})` }}>
          <InteractiveImage
            isDialogueOpenRef={isDialogueOpenRef}
            isDraggingRef={isDraggingRef}
            isGameInteractive={isGameInteractive}
            setBackgroundImage={setBackgroundImage}
            toggleFormulaire={toggleFormulaire}
            isMuted={isMuted}
          />
        </div>
      </div>
    </>
  );
}

export default Game;
