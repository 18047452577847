//GameIntroCarousel.js
import React, { useState, useEffect } from "react";
import { Howl } from "howler";

const GameIntroCarousel = ({ handleIntroClose, fadeOut }) => {
  const [sound, setSound] = useState(null);
  const [progress, setProgress] = useState(0);
  // const [volume, setVolume] = useState();
  useEffect(() => {
    // Initialiser le son une seule fois
    const newSound = new Howl({
      src: ["audio/Intro.mp3"],
      volume: 0.5,
      onplay: () => {
        console.log("Playing audio");
      },
      onend: () => {
        console.log("Audio ended");
      },
    });

    setSound(newSound); // Met à jour l'état avec l'objet Howl
    newSound.play(); // Joue le son

    // Nettoyage : arrêter le son lorsque le composant est démonté
    return () => {
      newSound.stop();
    };
  }, []); // Le tableau vide [] garantit que cet effet est exécuté une seule fois


  const handleIntroCloseWithAudioStop = () => {
    if (sound) {
      sound.stop();
      setSound(null);
    }
    handleIntroClose();
  };

  // const handleIntroMute = () => {
  //   if (sound) {
  //     sound.mute(!sound.mute());
  //   }
  // };

  return (
    <div className={`game-intro-dialogue intro ${fadeOut ? "fadeOut" : ""}`}>
      {/* <button onClick={handleIntroMute} className="mute-button">
        mute
      </button> */}
      <div className="dialog-container intro">
        <div className="dialog-box">
          <div style={{ fontSize: "83px", "textAlign": "center", fontFamily: "Caveat" }}>
            Hey,
          </div>
          <div style={{ fontSize: "23px", fontWeight: "bold" }}>
            C’EST AUJOURD’HUI, LE GRAND JOUR !
          </div>
          <p className="mt-4">
            <b>Tu dois te rendre à l’école en bus pour la première fois. Tu te sens prêt ?</b>
            Rassure-toi, tout va bien se passer, tout le monde est prêt à t’aider <b>et à te donner les bons tuyaux.</b>
          </p>
          <p>
            Il est 7h45 du matin, la sonnerie retentit à 8h30, ça te laisse trois bons quarts d’heure pour arriver.
            N’oublie pas que tu passes le <b>permis jeune mobilité</b> ce matin à l’école :
            <b> il va te permettre de prendre les transports en commun en toute sérénité.</b>
          </p>
          <p>
            Si tu es bien curieux et attentif, tu vas l’avoir les doigts dans le nez, tu verras. <br />
            <b>Commence par chercher Max,</b> le voisin d’en face, il va t’aider à monter dans le bus.
            Il doit déjà être à l’arrêt de bus, tu ne peux pas le louper, <b>il est toujours avec son skate.</b>
          </p>
          <div className="d-flex align-items-baseline justify-content-between mt-2">
            <div className="text-center" style={{ maxWidth: "130px" }}>
              <div>
                <img src="/img/sound-intro.png" alt="sound" className="img-fluid" />
              </div>
              <div className="fw-bold mt-2">
                Monte le son de ton casque
              </div>
            </div>
            <div className="text-center" style={{ maxWidth: "130px" }}>
              <div>
                <img src="/img/move.png" alt="sound" className="img-fluid" />
              </div>
              <div className="fw-bold mt-2">
                Déplace-toi dans le quartier
              </div>
            </div>
            <div className="text-center" style={{ maxWidth: "130px" }}>
              <div>
                <img src="/img/ask.png" alt="sound" className="img-fluid" />
              </div>
              <div className="fw-bold mt-2">
                Interroge les personnages
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button onClick={handleIntroCloseWithAudioStop} className="mybutton w-100">Cherche Max et son skate</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameIntroCarousel;
