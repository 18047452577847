import React, { useState } from "react";

function Question({ currentQuestion, totalQuestions, question, onAnswer }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [showWrongAnswer, setWrongAnswer] = useState(false);

  const handleOptionChange = (index) => {
    setSelectedOptions((prev) => {
      if (question.correctAnswers.length == 1) {
        return [index];
      }
      else {
        if (prev.includes(index)) {
          return prev.filter((option) => option !== index);
        } else {
          return [...prev, index];
        }
      }
    });
  };

  const handleSubmit = () => {
    // Pour test 
    // onAnswer(selectedOptions);

    if (selectedOptions.length === 0) return; // Ne pas soumettre si aucune option n'est sélectionnée

    const isCorrect =
      selectedOptions.length === question.correctAnswers.length &&
      selectedOptions.every((option) => question.correctAnswers.includes(option));

    setSubmitted(true);
    if (isCorrect == false) {
      setWrongAnswer(true);
    }
    else {
      setTimeout(() => {
        onAnswer(selectedOptions);
        setSubmitted(false);
        if (isCorrect == true) {
          setSelectedOptions([]);
        }
      }, 0); // Ajouter un délai pour l'effet de surbrillance
    }
  };

  const handleWrongAnswer = () => {
    setWrongAnswer(false);
    setSubmitted(false);
  };

  const isSelected = (index) => selectedOptions.includes(index);

  return (
    <>
      {showWrongAnswer && (
        <div className="center-container" style={{ zIndex: 1 }}>
          <div className="dialog-box error">
            <div style={{ fontSize: "53px", fontFamily: "Caveat" }}>
              Aië,
            </div>
            <div>
              C’est une mauvaise réponse,&nbsp;<b>ressaye !</b>
            </div>
            <button onClick={handleWrongAnswer} className="mybutton red mt-3">recommencer cette question</button>
          </div>
        </div>
      )}
      <div className="row flex-grow-1">
        <div className="col-6 d-flex flex-column px-2 border-end">
          <div className="d-flex align-items-baseline">
            <div className="fw-bold" style={{ fontSize: "57px" }}>
              Q{currentQuestion + 1}
            </div>
            <div style={{ fontSize: "19px" }}>
              / {totalQuestions}
            </div>
          </div>
          <div style={{ fontSize: "19px", lineHeight: "24px" }}>
            <div className="fw-bold text-uppercase">
              {question.question}
            </div>
            <div style={{ fontWeight: "300" }}>
              ({question.correctAnswers.length} bonne{question.correctAnswers.length > 1 ? "s" : ""} réponse{question.correctAnswers.length > 1 ? "s" : ""})
            </div>
          </div>
          <div className="mt-2" style={{ fontWeight: "300" }}>
            {question.info}
          </div>
          <div className="flex-grow-1"></div>
          <div className="text-center">
            <img src="/img/question.png" alt="question" className="img-fluid" />
          </div>
        </div>
        <div className="col-6 d-flex flex-column ps-5 pe-4">
          <ul className="fw-bold mx-auto" style={{ width: "358px" }}>
            {question.options.map((option, index) => (
              <li key={index} className="mt-3">
                <label className={`${submitted == false ? "cursor-pointer" : ""}`}>
                  <input
                    type={question.correctAnswers.length > 1 ? "checkbox" : "radio"}
                    name="optionGroup"
                    checked={isSelected(index)}
                    onChange={() => handleOptionChange(index)}
                    disabled={submitted}
                    className={`${submitted == false ? "cursor-pointer" : ""} me-2`} />
                  {option}
                </label>
              </li>
            ))}
          </ul>
          <div className="flex-grow-1"></div>
          <button onClick={handleSubmit} disabled={submitted || selectedOptions.length === 0} className={`mybutton w-100 ${submitted || selectedOptions.length === 0 ? "disabled" : ""}`}>
            question suivante
          </button>
        </div>
      </div>
    </>
  );
}

export default Question;
