import React from "react";

function Result() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/pdf/BADGE_75CM.pdf';
    link.download = 'BADGE_75CM.pdf';
    link.click();
  };

  return (
    <div className="row flex-grow-1">
      <div className="col-6 d-flex flex-column px-5 border-end">
        <div style={{ fontSize: "83px", fontFamily: "Caveat" }}>
          Félicitations !
        </div>
        <div className="flex-grow-1"></div>
        <div className="text-center">
          <img src="/img/result.png" alt="question" className="img-fluid" />
        </div>
      </div>
      <div className="col-6 px-5">
        <div className="fw-bold my-4" style={{ fontSize: "40px", lineHeight: "40px" }}>
          TU AS RÉUSSI LE TEST DU<br /> 
          PERMIS JEUNE MOBILITÉ
        </div>
        <button type="button" onClick={handleDownload} className="mybutton sapin w-100 mt-5">
          Télécharger mon badge
        </button>
      </div>
    </div>
  );
}

export default Result;
