//DialogueBox.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Howl } from "howler";

function DialogueBox({ onClose, slides, item, isMuted, sound, setSound }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [audioProgress, setAudioProgress] = useState(0);
  const [slideHistory, setSlideHistory] = useState([]);
  
  const stopSound = () => {
    if (sound) {
      sound.stop();
      setSound(null);
    }
  };

  // Met à jour la source audio avec Howler
  const updateAudioSource = useCallback(() => {

    if (slides[currentSlide]?.audioSrc) {
      // Si un son existe déjà, arrête-le
     stopSound();
      // Crée une nouvelle instance de Howl
      const newSound = new Howl({
        src: [slides[currentSlide].audioSrc],
        volume: 1,
        onplay: () => console.log("Lecture audio démarrée"),
        onend: () => {
          console.log("Lecture audio terminée");
          setAudioProgress(100);
        },
      });
      newSound.play();
      newSound.mute(isMuted);

      // Met à jour l'état avec l'instance Howl
      setSound(newSound);
    }
  }, [currentSlide, slides, sound, isMuted, setSound]);

  useEffect(() => {
    updateAudioSource();
  }, [currentSlide]);

  // Gère le mute/unmute lorsque isMuted change
  useEffect(() => {
    if (sound) {
      sound.mute(isMuted); // Active ou désactive le mute
    }
  }, [isMuted, sound]);

  // Surveille la progression de l'audio
  useEffect(() => {
    let interval;
    if (sound) {
      interval = setInterval(() => {
        const progress = (sound.seek() / sound.duration()) * 100;
        setAudioProgress(progress >= 100 ? 100 : progress);
      }, 100);
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Nettoie l'intervalle lorsque le son change ou que le composant est démonté
      }
    };
  }, [sound]);

  useEffect(() => {
    return () => {
     stopSound();
    };
  }, []);

  const handleNextSlide = useCallback(() => {
    const nextSlide = slides[currentSlide].nextSlide;
    if (nextSlide !== undefined) {
      setSlideHistory((prevHistory) => [...prevHistory, currentSlide]);
      setCurrentSlide(nextSlide);
    } else if (currentSlide < slides.length - 1) {
      setSlideHistory((prevHistory) => [...prevHistory, currentSlide]);
      setCurrentSlide((prev) => prev + 1);
    }
  }, [currentSlide, slides]);

  const handlePreviousSlide = useCallback(() => {
    if (slideHistory.length > 0) {
      const previousSlide = slideHistory[slideHistory.length - 1];
      setCurrentSlide(previousSlide);
      setSlideHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  }, [slideHistory]);

  const handleCloseDialogue = useCallback((cancel = false) => {
    stopSound();
    onClose(cancel);
  }, [onClose]);

  return (
    <div className="bottom-screen dialog fadeInUp">
      <div className="dialog-container dialog">
        <div className={`dialog-box ${slides[currentSlide].color ? slides[currentSlide].color : item.color ? item.color : ''}`}>
          <div className="dialog-container-img">
            <img src={slides[currentSlide].personSrc ?? item.personSrc} className="img-fluid" />
          </div>
          <div className="personnage">
            {slides[currentSlide].personName ?? item.personName}<br />
            {/* <pre>{JSON.stringify(slides, null, 2)}</pre> */}
          </div>
          <p className="mt-2" dangerouslySetInnerHTML={{ __html: slides[currentSlide].text }}></p>
          <div className="d-flex align-items-start gap-2">
            {slides.length > 1 && (
              <>
                <img onClick={handlePreviousSlide} src="/img/prev.png" className="cursor-pointer img-fluid" />
                <img onClick={handleNextSlide} src="/img/next.png" className="cursor-pointer img-fluid toto" />
              </>
            )}
            {slides[currentSlide].audioSrc && (
              <>
                <div className="audio-progress ms-1" style={{ position: "relative", top: "3px" }}>
                  <div className="progress-bar" style={{ width: `${audioProgress}%` }}                  >

                  </div>
                </div>
                <div className="fw-bold">
                  {currentSlide + 1}/{slides.length}
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center gap-3 mt-4" >
            {slides[currentSlide].buttonText ? (
              <button onClick={handleNextSlide} className={`mybutton w-100 ${slides[currentSlide].color ? slides[currentSlide].color : ''}`}>
                {slides[currentSlide].buttonText}
              </button>
            ) : (
              <button onClick={handleCloseDialogue} disabled={currentSlide != slides.length - 1} className={`mybutton w-100 ${slides[currentSlide].color ? slides[currentSlide].color : ''} ${currentSlide != slides.length - 1 ? "disabled" : ""}`}>
                {item.buttonText}
              </button>
            )}
            {item.buttonTextCancel && (
              <button onClick={() => handleCloseDialogue(true)} disabled={currentSlide != slides.length - 1} className={`mybutton w-100 ${slides[currentSlide].color ? slides[currentSlide].color : ''} ${currentSlide != slides.length - 1 ? "disabled" : ""}`}>
                {item.buttonTextCancel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogueBox;
