import React, { useState, useEffect, useRef } from "react";
import DialogueBox from "./components/DialogueBox";
import interactiveDataJson from "./data/interactiveData.json";

function InteractiveImage({ isDraggingRef, isDialogueOpenRef, isGameInteractive, setBackgroundImage, toggleFormulaire, isMuted }) {
  const [dialogueData, setDialogueData] = useState({
    show: false,
    content: "",
    key: 0,
    showButton: false,
    slides: [],
    item: null,
  });
  const [showIndiceContent, setShowIndiceContent] = useState(false);
  const [interactiveItems, setInteractiveItems] = useState([]);
  const [currentStep, setCurrentStep] = useState(2);
  const lastClickedItem = useRef(null);
  const [sound, setSound] = useState(null);

  const toggleIndiceContent = () => {
    setShowIndiceContent((prev) => !prev);
  };

  const updateCurrentStep = (newStep) => {
    if (newStep > 9) {
      // console.log("Le step ne peut pas dépasser 9.");
      return; // Empêche la mise à jour si le step dépasse 9
    }
    setCurrentStep(newStep);
  };

  const setIsDialogueOpenRef = (value) => {
    isDialogueOpenRef.current = value;
    const gameContainer = document.querySelector(".game-container");
    if (gameContainer) {
      gameContainer.style.cursor = value ? "default" : "pointer";
    }
  }

  useEffect(() => {
    setInteractiveItems(interactiveDataJson);
  }, []);

  const handleImageClick = (item, zone) => {
    if (isDraggingRef.current || isDialogueOpenRef.current) return; // Prevent click if dragging or dialogue is open
    setShowIndiceContent(false); // Fermer les indices lorsqu'une zone est cliquée

    if (lastClickedItem.current != item)
      lastClickedItem.current = item;

    lastClickedItem.currentZone = zone;
    zone.clicked = true;

    setDialogueData({
      show: true,
      content: zone.content[0].text,
      showButton: false,
      slides: zone.content,
      item: zone
    });

    setIsDialogueOpenRef(true);
  };

  const stopSound = () => {
    if (sound) {
      sound.stop();
      setSound(null);
    }
  };

  const handleClose = (cancel = false) => {
    stopSound(); // Arrête le son
    setDialogueData((prevData) => ({
      ...prevData,
      show: false,
    }));
    setIsDialogueOpenRef(false);
    if (cancel === true) return; // on ne fait pas la suite si on a cliqué sur le bouton annuler

    if (lastClickedItem.currentZone && lastClickedItem.currentZone.goquiz) {
      toggleFormulaire();
      return
    }

    
    const areAllZonesRequiredClicked = () => {
      if (!lastClickedItem.current || !lastClickedItem.current.zones) {
        return false; // Si aucune zone n'est associée, retournez false
      }

      // Vérifie uniquement les zones marquées comme "required"
      return lastClickedItem.current.zones
        .filter((zone) => zone.required)
        .every((zone) => zone.clicked === true);
    };
    if (areAllZonesRequiredClicked()) {
      console.log("Toutes les zones ont été cliquées. On passe à l'étape suivante.");
      if (lastClickedItem.current.changeBackground) {
        setBackgroundImage("tableau2.png");
      }
  
      // Mais vérifions qu'il n' y a pas de dialogue à afficher avant de passer à l'étape suivante
      // Si c'est le cas, on attendra que le dialogue soit fermé pour passer à l'étape suivante
      if (lastClickedItem.current.content && !lastClickedItem.current.contentOK) {
        lastClickedItem.current.contentOK = true;
        setTimeout(() => {
          setDialogueData({
            show: true,
            content: lastClickedItem.current.content[0].text,
            key: dialogueData.key + 1,
            showButton: false,
            slides: lastClickedItem.current.content,
            item: lastClickedItem.current,
          });
          setIsDialogueOpenRef(true);
        }, 300); // Délai de 300ms (ajustez selon vos besoins)
        return;
      }
      updateCurrentStep(currentStep + 1);
    } else {
      console.log("Il reste des zones non cliquées.");
    }

    lastClickedItem.currentZone = null;
  };

  const nextItem = interactiveItems.find((item) => item.step === currentStep);

  return (
    <>
      {isGameInteractive && (
        <div className="indice">
          <button className="mybutton indice" onClick={toggleIndiceContent}>
            indices
          </button>
          {showIndiceContent && (
            <div className="indice-container">
              <div className="indice-header">
                indices
              </div>
              <div className="indice-content">
                {nextItem?.hints?.map((hint, index) => (
                  <div key={index} className="indice-text cursor-default" style={{ textDecoration: nextItem?.zones.find((zone) => zone.id === hint.idZone)?.clicked ? "line-through" : "none", }}>
                    {hint.order}{")"} {hint.text}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ position: "relative" }}>
        {interactiveItems.map((item) => {
          if (item.step != currentStep) return null;
          return item.zones.map((zone) => {
            return (
              <div
                key={`zones-${zone.id}`}
                style={{
                  position: "absolute",
                  left: `${zone.coords.x}px`,
                  top: `${zone.coords.y}px`,
                  width: `${zone.width}px`,
                  height: `${zone.height}px`,
                  backgroundColor: `${zone.required ? "red" : "yellow"}`,
                  opacity: 0.2,
                  rotate: `${zone.rotate}deg`,
                  transformOrigin: "top left",
                }}
                onClick={() => handleImageClick(item, zone)} />
            );
          }
          );

        })}

        {dialogueData.show && (
          <DialogueBox
            onClose={handleClose}
            showButton={dialogueData.showButton}
            slides={dialogueData.slides}
            item={dialogueData.item}
            isMuted={isMuted}
            sound={sound}
            setSound={setSound}
          />
        )}
      </div>
    </>
  );
}

export default InteractiveImage;
