import React, { useState } from "react";
import Question from "./Question";
import Form from "./Form";
import Result from "./Result";
import questions from "../data/questions.json"; // Importez les questions depuis le fichier JSON

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [startQuiz, setStartQuiz] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const totalQuestions = questions.length;

  const showQuestion = showResult == false && startQuiz == true;

  const handleStart = () => {
    setStartQuiz(true);
  };

  const handleAnswer = (selectedOptions) => {
    // const correctAnswers = questions[currentQuestion].correctAnswers;
    // const isCorrect =
    //   selectedOptions.length === correctAnswers.length &&
    //   selectedOptions.every((option) => correctAnswers.includes(option));

    // if (isCorrect) {
    setScore(score + 1);
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
    // }
  };

  return (
    <div className="center-screen fadeInUp">
      <div className="dialog-container question">
        <div className={`dialog-box d-flex question transition move 
        ${showQuestion == true && questions[currentQuestion]?.color ? questions[currentQuestion].color : showResult ? 'sapin' : 'rose_fonce'}`}>
          {startQuiz == false ? (
            <Form onStart={handleStart} />
          ) : (
            showResult ? (
              <Result score={score} totalQuestions={totalQuestions} />
            ) : (
              <Question
                currentQuestion={currentQuestion}
                totalQuestions={totalQuestions}
                question={questions[currentQuestion]}
                onAnswer={handleAnswer} />
            )
          )}
        </div>
        {!showResult &&
          [...Array(2)].map((_, index) => {
            const offset = (startQuiz == false) ? 0 : 1;
            const nextQuestionIndex = currentQuestion + index + offset;
            return (
              questions[nextQuestionIndex] && (
                <div key={index} className={`dialog-box transition move ${questions[nextQuestionIndex].color || ''}`}>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}

export default Quiz;
